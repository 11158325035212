import axios from 'axios';
import {USER_LOGGED_IN , USER_LOGIN_FAILED, API_URL , ANALYITICS_FETCHED , USER_LOGIN_ATTEMPT , USER_LOGIN_COMPLETED}  from  '../constants.js';
import { error } from 'react-notification-system-redux';

const notificationOpts = {
    uid: 'incorrect_item',
    title: 'Username or Password incorrect',
    position: 'tr',
    autoDismiss: 5,
  };

export default function LoginSubmit(username , password){
    // console.log(username , password);
    return dispatch => {
        dispatch({type:USER_LOGIN_ATTEMPT , payload:true});
        var headers = {
            'Content-Type': 'application/json' 
        }
        const data = {
            email:username,
            password:password
        }
        axios.post(`${API_URL}/login`, data, {headers: headers})
        .then((response) => {
            // console.log(response.data[0]);
            localStorage.setItem('token' , response.data[0].token);
            this.history.push('/dashboard/home');
            dispatch({type: USER_LOGGED_IN, payload:response.data[0]})
            dispatch(error(notificationOpts));
        })
        .catch((err) => {
            // console.log(err);
            dispatch(error(notificationOpts));
            dispatch({type: USER_LOGIN_FAILED})

        })
        dispatch({type:USER_LOGIN_COMPLETED , payload:true});
    }
}

export function logout(){
    localStorage.clear();
   window.location.assign('/')
}

export function fetchThisCampaign(){
    let  headers = {
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    return dispatch => {
        axios.get(`${API_URL}/analyitics`,{headers: headers})
        .then((response) => {
            dispatch({type:ANALYITICS_FETCHED, payload: response.data})
        })
        .catch((error) => {
            console.log(error);
        })
    }
}