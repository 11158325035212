import axios from 'axios';

import {API_URL ,CAMPAIGN_FETCHED , ALL_CAMPAIGNS_FETCHED , STATUS_UPDATE_SUCCESS ,STATUS_UPDATE_FAILED} from '../constants';
import { success , error } from 'react-notification-system-redux';
const notificationOpts = {
    uid: 'campaign_changed',
    title: 'Campaign Status Changed',
    position: 'tr',
    autoDismiss: 5,
  };
  const notificationOptsError = {
    title: 'Campaign Status Not Changed',
    message: "This campaign can't be activated because , anothe campaign is already activated",
    position: 'tr',
    autoDismiss: 5,
  };
export  function fetchCampaign(id){
    // console.log('call' , id);
    let  headers = {
        'Content-Type': 'application/x-www-form-urlencoded' ,
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    return dispatch => {
            axios.get(`${API_URL}/campaign/${id}`,{headers: headers})
            .then((response) => {
                // console.log(response , 'resp');
                dispatch({type:CAMPAIGN_FETCHED , payload: response.data})
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

export function allCampaigns(){
    let  headers = {
        'Content-Type': 'application/x-www-form-urlencoded' ,
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    return dispatch => {
        axios.get(`${API_URL}/campaigns`,{headers: headers})
        .then((response) => {

            dispatch({type:ALL_CAMPAIGNS_FETCHED , payload: response.data})
        })
        .catch((error) => {
            console.log(error);
        })
    }
}

export function changeStatus(status_id , id ){
    // console.log(localStorage.getItem('token'));
    let  headers = {
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const data = {
        'campaign_id' : id,
        'status_id' :status_id
    }
    return dispatch => {
        axios.post(`${API_URL}/activate_campaign` ,data,{headers: headers})
        .then((response) => {
            console.log(response , 'response');
            if(response.data.status){
                // fetchCampaign(id)
                // console.log(response.new_status);
                dispatch({type:STATUS_UPDATE_SUCCESS , payload:response.data.new_status})
                dispatch(success(notificationOpts));
            }else{
                dispatch(error(notificationOptsError));
                dispatch({type:STATUS_UPDATE_FAILED , payload: response.data.new_status})
            }
        })
        .catch((error) => {
            console.log(error);
        })
}
}