import React, { Component } from "react";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";
// import Sidebar from '../components/Sidebar/Sidebar.jsx'
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import {Table , Button} from 'react-bootstrap';
import {allCampaigns} from '../actions/CampaignActions';

// dinamically create dashboard routes
// import dashboardRoutes from '../../routes/dashboard.jsx';

// style for notifications
import { style } from "../variables/Variables.jsx";
import Card from "../components/Card/Card.jsx";
import { connect } from "react-redux";

var ps;

class AllCampaigns extends Component {
  constructor(props) {
    super(props);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null,
    };
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
   this.props.doFetchAllCampaigns();
   
  }

  componentWillReceiveProps(){
      let owners = this.props.owners 
      this.setState(owners);
  }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  }

  onView(event){
    this.props.history.push(`/dashboard/campaign/${event}`);
  }
  renderCampaigns(){
        const kopp  = (this.props.campaigns);
        return (kopp && kopp.campaigns && kopp.campaigns.length>0)
        ? kopp.campaigns.map((campaign , index) => (
        <tr key={Math.random()}>
          <td>{campaign.campaign_name}</td>
          <td>{campaign.business_name}</td>
          <td>{campaign.start_date}</td>
          <td>{campaign.end_date}</td>
          <td>{campaign.campaign_type}</td>
          <td>{campaign.status_name}</td>
          <td><Button varient="outline" onClick={()=> this.onView(campaign.id) }>View</Button></td>
        </tr>))
   : (
    <tr key={1}>
        <td>{'Not Available'}</td>
        <td>{'Not Available'}</td>
        <td></td>
        <td></td>
        <td>{'Not Available'}</td>
        <td>{'Not Available'}</td>
    </tr>)
  }
  render() {
   
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} />
        <div
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
         <div className="content">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <Card
                        title="Campaigns"
                        content={
                        <div> 
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th> Campaign name</th>
                                        <th> Shop Name</th>
                                        <th> Start Date</th>
                                        <th> End Date</th>
                                        <th> Campaign Type </th>
                                        <th> Status</th>
                                        <th> Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {this.renderCampaigns()}
                                </tbody>
                            </Table>    
                        </div>
                        } // Card content ends
                    />
                    </div>
                </div>
            </div>
        </div>

          <Footer fluid />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
    campaigns : state.campaign
});
const mapActionsToProps = {
  doFetchAllCampaigns : allCampaigns
}


export default connect(mapStateToProps , mapActionsToProps) (AllCampaigns);