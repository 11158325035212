import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {store} from './store';
// import {createStore , combineReducers , applyMiddleware , compose} from 'redux';
// import thunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
// import AuthReducer from './reducers/AuthReducer';
// import CustomerReducer from './reducers/CustomerReducer';
// import CampaignReducer from './reducers/CampaignReducer';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.1.1";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/demo.css";

// const allReducers = combineReducers({
//     auth:AuthReducer,
//     customer:CustomerReducer,
//     campaign:CampaignReducer
// })
// const allStoreEnhancers = compose(
//     applyMiddleware(thunk),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// )

// const store = createStore( allReducers , allStoreEnhancers )

ReactDOM.render(<Provider store={store} ><App /> </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
