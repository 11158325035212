// export const API_URL = "http://ec2-18-224-253-81.us-east-2.compute.amazonaws.com/api/admin";
export const API_URL = "https://daddyspocket.com/api/admin";

// export const API_URL = "http://127.0.0.1:8000/api/admin";
export const USER_LOGGED_IN = "user:LOGGED_IN";
export const USER_LOGGED_OUT = "user:LOGGED_OUT";
export const USER_LOGIN_FAILED = "user:LOG_IN_FAILED";
export const USER_LOGIN_ATTEMPT = "user:LOGIN_ATTEMPTED";
export const USER_LOGIN_COMPLETED = "user:LOGIN_COMPLETED";
export const USERS_FETCHED = "customer:USER_FETCH_SUCCESS";
export const USER_DETAILS_FETCHED = "customer:USER_DETAILS_FETCHED";
export const CAMPAIGN_FETCHED = "campaign:CAMPAIGN_FETCHED";
export const ALL_CAMPAIGNS_FETCHED = "cmapaign:ALL_CAMPAIGN_FETCHED";
export const VALIDITY_EXTENDED = 'customer:VALIDITY_EXTENDED';
export const STATUS_UPDATE_FAILED = 'campaign:UPDATE_FAILED';
export const STATUS_UPDATE_SUCCESS = 'campaign:UPDATE_SUCCESS';
export const ANALYITICS_FETCHED = 'user:ANALYITICS_FETCHED';
