
import {CAMPAIGN_FETCHED , ALL_CAMPAIGNS_FETCHED , STATUS_UPDATE_FAILED , STATUS_UPDATE_SUCCESS} from '../constants';

export default function CampaignReducer(state =[] , {type , payload}){
    switch(type){
        case CAMPAIGN_FETCHED:return payload;
        case ALL_CAMPAIGNS_FETCHED:return payload;
        case STATUS_UPDATE_SUCCESS: 
                return Object.assign({}, state, {
                    campaign_status:[payload]
                } );
        case STATUS_UPDATE_FAILED:
        return Object.assign({}, state, {
                campaign_status:[payload]
            });
        default:return state;
    }
}