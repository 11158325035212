import React, { Component } from "react";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";
// import Sidebar from '../components/Sidebar/Sidebar.jsx'
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import {Col} from 'react-bootstrap';
import {fetchThisCampaign} from '../actions/AuthAction';
import {connect} from 'react-redux';
// dinamically create dashboard routes
// import dashboardRoutes from '../../routes/dashboard.jsx';

// style for notifications
import { style } from "../variables/Variables.jsx";
import Card from "../components/Card/Card.jsx";

var ps;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null
    };
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    this.props.onFetchDetails();
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  }
  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} />
        <div
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
            <div className="content">
              <div className="clearfix">
                <Col sm={4}>
                  <Card
                   title="Users this week"
                   content = {
                    <div className="users">
                        <h1>{this.props.users && this.props.users.owners ? this.props.users.owners : 0} </h1>
                    </div>
                   }
                  >

                  </Card>
                </Col>
                <Col sm={4}>
                  <Card
                   title="Revenue this week"
                   content = {
                    <div className="users">
                        <h1> ₹{this.props.users && this.props.users.revenue ? this.props.users.revenue : 0} </h1>
                    </div>
                   }
                  >

                  </Card>
                </Col>
              </div>
            </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  users : state.auth
})
const dispatchActionsToProps = {
  onFetchDetails : fetchThisCampaign
}
export default connect(mapStateToProps , dispatchActionsToProps) (Dashboard);