import React, { Component } from "react";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
// import NotificationSystem from "react-notification-system";
// import Sidebar from '../components/Sidebar/Sidebar.jsx'
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Card from '../components/Card/Card.jsx';
import Datetime from "react-datetime";
import {Table , Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {fetchDetails , extendValidity } from '../actions/CustomerActions';
import {customNotificationError} from '../actions/NotificationActions';
import Notifications from 'react-notification-system-redux';
// dinamically create dashboard routes
// import dashboardRoutes from '../../routes/dashboard.jsx';

// style for notifications
// import { style } from "../variables/Variables.jsx";

var ps;

class Details extends Component {
  constructor(props) {
    super(props);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null,
      validity:new Date(this.props.owner && this.props.owner.subs && this.props.owner.subs.length > 0 ? this.props.owner.subs[0].expires_on : "1995-06-02"),
    };
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    this.props.onFetchCustomer(this.props.match.params.id);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  }

  renderMedias(){
    // <img src="asdasd" className="center-block img-responsive" alt="media"/> 
    const kopp  = (this.props.owner);
    return (kopp && kopp.medias && kopp.medias.length>0)
      ? kopp.medias.map((media) => (
        <img key={Math.random()} src={media.url} className="center-block img-responsive" alt="media"/> 
      ))  
      :(
          'No Medias Found'
      )
  }
  renderCampaign(id){
    this.props.history.replace(`/dashboard/campaign/${id}`)
  }
  renderCampaigns(){

    const kopp  = (this.props.owner);
    // console.log((kopp && kopp.campaigns && kopp.campaigns.length > 0) ? kopp.campaigns : null ) 
    return (kopp && kopp.campaigns && kopp.campaigns.length > 0) ? (
      kopp.campaigns.map((campaign) => (
        <tr key={Math.random()}>
          <td>{campaign.campaign_name}</td>
          <td>{campaign.start_date}</td>
          <td>{campaign.end_date}</td>
          <td>{campaign.status_name}</td>
          <td><Button variant="primary" onClick={()=> this.renderCampaign(campaign.id)}>View</Button></td>
        </tr>
      ))
    ) : (
      <tr>
         <td>Not  avaliable</td>
         <td>Not  avaliable</td>
         <td>Not  avaliable</td>
         <td>Not  avaliable</td>
         <td>Not  avaliable</td>
      </tr>
    );
  }
  onDateChange(event , id){
    // console.log('fired' , event.toDate().getMonth(), id)
    // let date = JSON.stringify(event.toDate())
    //     date = date.slice(1,11)
    let date_s = new Date(this.props.owner && this.props.owner.user ? this.props.owner.user.created_at : new Date());
    
    var d = new Date(event),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if(date_s < d){
      const jq =  [year, month, day].join('-');
      this.props.onValidityExtend(jq , id);
    }else{
      this.props.onSendNotification({
      title: 'Oops!!!!',
      message: "You're selected date before he joining with us! Please select a valid date",
      position: 'tr',
      autoDismiss: 5,
    })
    }

  }

  render() {
    const {notifications} = this.props;
    const style = {
      NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          margin: '10px 5px 2px 1px'
        },
      }
    }



    return (
      <div className="wrapper">
       <Notifications
        notifications={notifications}
        style={style}
      />
        {/* <NotificationSystem ref="notificationSystem" style={style} /> */}
        <Sidebar {...this.props} />
        <div
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
         <div className="content">
            <div className="clearfix">
              <div className="col-md-6">
              <Card
                title="User details"
                statsIcon="fa fa-check"
                content={
                  <div className="contents">
                    <div className="row">
                        <div className="col-xs-6"> Name </div>
                        <div className="col-xs-6"> <span className="font-bold">{this.props.owner && this.props.owner.user ? `${this.props.owner.user.owner_name}  ${this.props.owner.user.owner_last_name}` : '' }  </span></div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6"> Shop Name </div>
                        <div className="col-xs-6"> <span className="font-bold"> {this.props.owner && this.props.owner.profile_data ? this.props.owner.profile_data.business_name : '' }  </span></div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6"> Phone </div>
                        <div className="col-xs-6"> <span className="font-bold"> {this.props.owner && this.props.owner.user ? `${this.props.owner.user.phone}` : '' }  </span></div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6"> Email </div>
                        <div className="col-xs-6"> <span className="font-bold"> {this.props.owner && this.props.owner.user ? `${this.props.owner.user.email}` : '' } </span></div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6"> Joined </div>
                        <div className="col-xs-6"> <span className="font-bold">  {this.props.owner && this.props.owner.user ? `${this.props.owner.user.created_at}` : '' } </span></div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6"> Validity <small> (Change to extent ) </small> </div>
                        <div className="col-xs-6"> 
                          {/* <span className="font-bold"> {this.props.owner && this.props.owner.subs && this.props.owner.subs.length > 0 ? `${this.props.owner.subs[0].expires_on}` : '' } </span> */}
                          <Datetime
                            timeFormat={false}
                            dateFormat={'YYYY-MMMM-DD '}
                            inputProps={{ placeholder: "Validity of the customer plan" }}
                            onChange={(event) => this.onDateChange(event , this.props.owner && this.props.owner.user ? this.props.owner.user.id:0 )}
                            value={new Date(this.props.owner && this.props.owner.subs && this.props.owner.subs.length > 0 ? this.props.owner.subs[0].expires_on : "1995-06-02")}
                            
                     />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6"> Plan </div>
                        <div className="col-xs-6"> <span className="font-bold"> {this.props.owner && this.props.owner.subs && this.props.owner.subs.length > 0 ? `${this.props.owner.subs[0].plan_name}` : '' } </span></div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6"> Total Transactions </div>
                        <div className="col-xs-6"> <span className="font-bold">  {this.props.owner && this.props.owner.transactions !== null ? `${this.props.owner.transactions}` : '' }  </span></div>
                    </div>
                  </div>
                }
         
              />
              </div>
              <div className="col-md-6">
              <Card
                title="User Medias"
                statsIcon="fa fa-check"
                content={
                  <div className="contents">
                    <div className="row">
                        <div className="col-xs-12 logoItem">
                          <h4> Logo</h4>
                          <div className="imageHolder">
                              {this.props.owner && this.props.owner.logo && this.props.owner.logo.url ?  <img src={this.props.owner.logo.url} className="center-block img-responsive" alt="Logo"/>  : 'Logo Not Found' } </div>
                          </div>
                    </div>
                    <div className="row"> 
                        <div className="col-xs-3"> All medias </div>
                        <div className="col-xs-9 liquidItem"> 
                            {this.renderMedias()}
                        </div>
                    </div>
                    
                  </div>
                }
         
              />
              </div>
              <div className="col-sm-12">
              <Card
                title="Campaigns"
                statsIcon="fa fa-check"
                content={
                  <Table striped hover>
                    <thead>
                        <tr>
                            <th> Name</th>
                            <th> Start Date</th>
                            <th> End Date</th>
                            <th> Status </th>
                            <th> Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                      {this.renderCampaigns()}
                    </tbody>
                  </Table>    
                }
         
              />
              </div>
            </div>            
        </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  owner : state.customer,
  notifications:state.notifications
});
const mapActionToProps ={
  onFetchCustomer : fetchDetails,
  onValidityExtend:extendValidity,
  onSendNotification:customNotificationError

};
export default connect(mapStateToProps , mapActionToProps)(Details);