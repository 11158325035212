import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import Card from "../components/Card/Card.jsx";
import Button from "../components/CustomButton/CustomButton.jsx";
import LoginSubmit from '../actions/AuthAction';
import {connect} from 'react-redux';
import Notifications from 'react-notification-system-redux';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      email:'',
      password:'',
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
    // this.props.onSubmit(this.state.email , this.state.password );
  }
  onInputChange(event){
    this.setState({
      [event.target.name] : event.target.value
    }) ;
  }
  onSubmit(){
        this.props.onSubmit(this.state.email , this.state.password );
    // this.props.history.push(`/dash`);
    
  }
  render() {
    const {notifications} = this.props;
    const style = {
      NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          margin: '10px 5px 2px 1px'
        },
      }
    }
    // console.log(, 'fase' , this.props.auth)
    return (
      <Grid>
      <Notifications
      notifications={notifications}
      style={style}
    />
        <Row className="vcenter_block">
          <Col md={4} sm={6} className="vCenter">
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Admin Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Email address</ControlLabel>
                      <FormControl placeholder="Enter email" type="email" name='email' value={this.state.email} onChange={this.onInputChange} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl placeholder="Password" type="password" name='password' value={this.state.password} onChange={this.onInputChange} />
                    </FormGroup>
                  </div>
                }
                legend={
                  <Button bsStyle="primary" fill wd onClick={this.onSubmit}  >
                  { (this.props.auth && this.props.auth.loading) === false  ?( <i className="pe-7s-refresh loading_button"></i>) : ''}
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
 return {
  auth:state.auth,
  notifications:state.notifications

 }
}
const mapActionsToProps = {
  onSubmit:LoginSubmit
}
export default connect(mapStateToProps , mapActionsToProps , null)(LoginPage);
