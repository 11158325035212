import { success , error } from 'react-notification-system-redux';
export function customNotificationError(option){
    return dispatch => {
        dispatch(error(option));
    }
}
export function customNotificationSuccess(option){
    return dispatch => {
        dispatch(success(option));
    }
}