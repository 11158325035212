import React , {Component} from 'react';
import {BrowserRouter , Route , Switch} from 'react-router-dom';

import LoginPage from './containers/LoginPage';
import Dashboard from './containers/Dashboard';
import Campaigns from './containers/Campaigns';
import Users from './containers/Users';
import Details from './containers/Details';
import AllCampaigns from './containers/AllCampaigns';
import NotificationSystem from 'react-notification-system';
import { style } from './variables/Variables';
// import {histroy} from './'

class App extends Component{
  render(){
    return (
      <div className="App">
        <NotificationSystem ref="notificationSystem" style={style} /> 
        <BrowserRouter >
          <Switch>
            <Route path="/dashboard/home" exact component={Dashboard}/>
            <Route path="/dashboard/campaign" exact component={AllCampaigns}/>
            <Route path="/dashboard/campaign/:id" exact component={Campaigns}/>
            <Route path="/dashboard/users" exact component={Users}/>
            <Route path="/dashboard/users/:id" exact component={Details}/>
            <Route path="/" exact component={LoginPage}/>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
