import {USERS_FETCHED , USER_DETAILS_FETCHED , VALIDITY_EXTENDED} from '../constants';
export default function CustomerReducer(state = [] , {type , payload}){
    switch(type){
        case USERS_FETCHED:return payload;
        case USER_DETAILS_FETCHED:return payload;
        case VALIDITY_EXTENDED:
            const plan = state.subs[0].plan_name;
            return Object.assign({}, state, {
                subs: [{
                    expires_on: payload,
                    plan_name: plan
                }]
            } )
        default:return state;
    }
}