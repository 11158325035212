import React, { Component } from "react";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";
// import Sidebar from '../components/Sidebar/Sidebar.jsx'
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
// dinamically create dashboard routes
// import dashboardRoutes from '../../routes/dashboard.jsx';

// style for notifications
import { style } from "../variables/Variables.jsx";
import Card from "../components/Card/Card.jsx";
import { connect } from "react-redux";
import {fetchCustomers} from '../actions/CustomerActions';

var ps;

class Users extends Component {
  constructor(props) {
    super(props);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.state = {
      _notificationSystem: null,
    };
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
   this.props.doFetchUsers();
   
  }

  // componentWillReceiveProps(){
  //     let owners = this.props.owners 
  //     this.setState(owners);
  // }
  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    // }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  }

  onView(event){
    this.props.history.push(`/dashboard/users/${event}`);
  }
//   renderUsers(){
//         const kopp  = (this.props.owners);
//         return (kopp && kopp.owners && kopp.owners.length>0)
//         ? kopp.owners.map((owner , index) => (
//           <tr key={index}>
//               <td>{owner.id}</td>
//               <td>{`${owner.owner_name} ${owner.owner_last_name}`}</td>
//               <td>{owner.business_name}</td>
//               <td>{owner.joined_at}</td>
//               <td>{owner.expiry}</td>
//               <td>{owner.transactions}</td>
//               {/* <td></td> */}
//               <td>{owner.plan_name}</td>
//               <td>{owner.phone}</td>
//               <td>{owner.email}</td>
//               <td> <Button variant="primary" onClick={() => this.onView(owner.id)}>View</Button></td>
//    </tr>))
//    : (
//     <tr key={1}>
//         <td>{'Not Available'}</td>
//         <td>{'Not Available'}</td>
//         <td></td>
//         <td>{'Not Available'}</td>
//         <td>{'Not Available'}</td>
//         <td>{'Not Available'}</td>
//         <td>{'Not Available'}</td>
//         <td>Actions</td>
// </tr>)
//   }
onRowClick(r){
  this.props.history.push(`/dashboard/users/${r.id}`)
}
render() {
  const selectRow = {
    onRowClick: this.onRowClick
  };
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} />
        <div
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
         <div className="content">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <Card
                        title="Shop Users"
                        content={
                        <div> 
                            {/* <Table striped hover>
                                <thead>
                                    <tr>
                                       <th> No.</th>
                                        <th> Name</th>
                                        <th> Shop Name</th>
                                        <th> Joined on</th>
                                        <th> Expiry</th>
                                        <th> Transactions</th>
                                        <th> Plan</th>
                                        <th> Phone</th>
                                        <th> Email</th>
                                        <th> Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderUsers()}
                                </tbody>
                            </Table>     */}
                              <BootstrapTable pagination data={(this.props.owners && this.props.owners.owners && this.props.owners.owners.length>0)? this.props.owners.owners : null} striped hover
                                search  options={ selectRow }
                              >
                                <TableHeaderColumn isKey dataField='id'> ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='owner_name'>Owner Name</TableHeaderColumn>
                                <TableHeaderColumn dataField='business_name'>Business name</TableHeaderColumn>
                                <TableHeaderColumn dataField='joined_at'>Joined On</TableHeaderColumn>
                                <TableHeaderColumn dataField='expiry'>Expiry</TableHeaderColumn>
                                <TableHeaderColumn dataField='transactions'>Transactions</TableHeaderColumn>
                                <TableHeaderColumn dataField='plan_name'>Plan</TableHeaderColumn>
                                <TableHeaderColumn dataField='phone'>Phone</TableHeaderColumn>
                                <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
                                {/* <TableHeaderColumn dataField='action' dataFormat={this.actionTable}>Action</TableHeaderColumn> */}
                            </BootstrapTable>
                        </div>
                        } // Card content ends
                        legend={
                          <div className="legend">
                             Total of {(this.props.owners && this.props.owners.owners && this.props.owners.owners.length>0)? this.props.owners.owners.length : 0 } Shop owners
                          </div>
                      }
                    />
                    </div>
                </div>
            </div>
        </div>

          <Footer fluid />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
    owners : state.customer
});
const mapActionsToProps = {
  doFetchUsers : fetchCustomers
}


export default connect(mapStateToProps , mapActionsToProps) (Users);