
import AuthReducer from './reducers/AuthReducer';
import CustomerReducer from './reducers/CustomerReducer';
import CampaignReducer from './reducers/CampaignReducer';
import {createStore , combineReducers , applyMiddleware , compose} from 'redux';
import {reducer as notifications} from 'react-notification-system-redux';
import thunk from 'redux-thunk';
const allReducers = combineReducers({
    auth:AuthReducer,
    customer:CustomerReducer,
    campaign:CampaignReducer,
    notifications:notifications

})
const allStoreEnhancers = compose(
    applyMiddleware(thunk)
    // ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export  const  store = createStore( allReducers , allStoreEnhancers )