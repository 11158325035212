import axios from 'axios';

import {API_URL , USERS_FETCHED , USER_DETAILS_FETCHED , VALIDITY_EXTENDED} from '../constants';
import { success  } from 'react-notification-system-redux';
// import {store} from '../store';
const notificationOpts = {
    title: 'Validity Updated',
    message: 'The Free Trial Period of this customer has Changed',
    position: 'tr',
    autoDismiss: 5,
  };

export  function fetchCustomers(){
    let  headers = {
        'Content-Type': 'application/x-www-form-urlencoded' ,
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    return (dispatch) => {
        axios.get(`${API_URL}/all_users`,{headers: headers})
        .then((response) => {
            // console.log(response , 'data');
            dispatch({type:USERS_FETCHED , payload: response.data});
           
        })
        .catch((error) => {
            console.log(error);
        })
    }    
}

export  function stateCheck(){

}

export function fetchDetails(user_id){
    let  headers = {
        'Content-Type': 'application/x-www-form-urlencoded' ,
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    return dispatch => {
        axios.get(`${API_URL}/users/${user_id}` ,{headers: headers})
        .then((response) => {
            dispatch({type:USER_DETAILS_FETCHED , payload: response.data})
        })
        .catch((error) => {
            console.log(error);
        })
    }
}

export function extendValidity(date , id){
    let  headers = {
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    const data = {
        'id' : id,
        'date' : date
    }
    return dispatch => {
        axios.post(`${API_URL}/extend_validity` ,data,{headers: headers})
        .then((response) => {
            // console.log(store.getState() ,   'data');
            dispatch({type:VALIDITY_EXTENDED , payload: response.data.new_date})
            dispatch(success(notificationOpts));
        })
        .catch((error) => {
            console.log(error);
        })
    }
}
