import React, { Component } from "react";
import {Button} from "react-bootstrap";
import {connect} from 'react-redux';
import {logout} from '../../actions/AuthAction';
class HeaderLinks extends Component {
  logout(){
    this.props.onLogout();
  }
  render() { 
    return (
      <div>
        <Button variant="outline-primary" className="pull-right" onClick={() => this.logout()}>Logout</Button>
      </div>
    );
  }
}

const dispatchActionsToProps = {
  onLogout:logout
}
export default connect(null , dispatchActionsToProps)(HeaderLinks);
