import React, { Component } from 'react';
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// react component that creates notifications (like some alerts with messages)
// import NotificationSystem from 'react-notification-system';
import Sidebar from '../components/Sidebar/Sidebar.jsx'
import { connect } from 'react-redux';
import Select from 'react-select';
// import Sidebar from '../components/Sidebar/Sidebar.jsx';
import Header from '../components/Header/Header.jsx';
import Footer from '../components/Footer/Footer.jsx';
import Card from '../components/Card/Card';
import 'react-select/dist/react-select.css';
import { fetchCampaign , changeStatus  } from '../actions/CampaignActions';
import Notifications from 'react-notification-system-redux';
// import {Col , Button } from 'react-bootstrap';
// dinamically create dashboard routes
// import dashboardRoutes from '../../routes/dashboard.jsx';

// style for notifications
// import { style } from '../variables/Variables.jsx';

let ps;

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null,
    };
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    this.props.onCampaignFetch(this.props.match.params.id);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  componentDidUpdate(e) {
    if (navigator.platform.indexOf('Win') > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993
      && e.history.action === 'PUSH'
      && document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
    // this.setState({
    //   singleSelect:this.props.campaigns && this.props.campaigns.details ? this.props.campaigns.details[0].status_code : 0
    // })
  }

  componentWillMount() {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }

  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position) {
    const color = Math.floor(Math.random() * 4 + 1);
    let level;
    switch (color) {
      case 1:
        level = 'success';
        break;
      case 2:
        level = 'warning';
        break;
      case 3:
        level = 'error';
        break;
      case 4:
        level = 'info';
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to
          {' '}
          <b>Light Bootstrap Dashboard</b>
          {' '}
- a beautiful freebie for
          every web developer.
        </div>
      ),
      level,
      position,
      autoDismiss: 15,
    });
  }

  renderCampaigns() {
    const kopp = (this.props.campaigns);
    if(kopp && kopp.details && kopp.details[0].campiagn_type_id === 2){
      return (kopp && kopp.deals && kopp.deals.length > 0) ? (
        <div className="row">
        {kopp.deals.map(deal => (
            <div className="col-sm-4" key={Math.random()}>
                <div className="visit_item">
                  <div className="visit_name">
                  Visit No :
                    {' '}
                    <span className="font-bold">{deal.visit_no}</span>
                  </div>
                  <div className="discount">
                      {' '}
                      {deal.rate}
                        % discount on minimum purchase of
                      {' '}
                      {deal.min_limit}
                      {' '}
                    </div>
                  </div>
            </div>
        ))}
        </div>
        ) : ( <div className="row"> <div className="no_visits col-sm-4">No visits added yet</div> </div>);
    }else{
      return (
        <div className="row">
          <div className="col-sm-4" key={Math.random()}>
            <div className="visit_item points">
              <div className="visit_name">
            Point awarding :
                {' '}
              </div>
              <div className="discount">
                <span className="font-bold">{this.props.campaigns && this.props.campaigns.point ? this.props.campaigns.point.rewards : '0'}</span>
                points per 
                <span className="font-bold">{this.props.campaigns && this.props.campaigns.point ? this.props.campaigns.point.points_per : '0'}</span>
                with minimum purchase of 
                <span className="font-bold">{this.props.campaigns && this.props.campaigns.point ? this.props.campaigns.point.min_limit : '0'}</span>

                
              </div>
            </div>
          </div>
          <div className="col-sm-4" key={Math.random()}>
        <div className="visit_item points">
          <div className="visit_name">
        Redemptions:
            {' '}
          </div>
          <div className="discount">
            <span className="font-bold">{this.props.campaigns && this.props.campaigns.redemptions ? this.props.campaigns.redemptions.items_for_redemption : '0'}</span>
            in redepmtion of 
            <span className="font-bold">{this.props.campaigns && this.props.campaigns.redemptions ? this.props.campaigns.redemptions.points_needed : '0'}</span>
           points

             
          </div>
        </div>
      </div>
        </div>
      )
    }

//     return (kopp && kopp.campaigns && kopp.details.length > 0) ? (
//       kopp.campaigns.map(campaign => (
//         <div className="col-sm-4" key={Math.random()}>
//           <div className="visit_item">
//             <div className="visit_name">
//             Visit No :
//               {' '}
//               <span className="font-bold">{campaign.visit_no}</span>
//             </div>
//             {campaign.discount_rule && campaign.discount_rule !== null ? (
//               <div className="discount">
//                 {' '}
//                 {campaign.discount_rule.rate}
// % discount on minimum purchase of
//                 {' '}
//                 {campaign.discount_rule.min_limit}
//                 {' '}
//               </div>
//             ) : (<div className="discount">No Discount specified on This visit</div>)}
//             {campaign.point_rule && campaign.point_rule !== null ? (
//               <div className="points">
//                 {' '}
//                 {campaign.point_rule.rewards}
//                 {' '}
// Points per
//                 {' '}
//                 {campaign.point_rule.points_per}
//                 {' '}
// above
//                 {' '}
//                 {campaign.point_rule.min_limit}
//               </div>
//             ) : (<div className="points">No Points specified on This visit</div>)}
//           </div>
//         </div>
//       ))
//     ) : (<div className="no_visits col-sm-4">No visits added yet</div>);
  }

  onChangeState(value){
    this.props.onUpdateStatus(value.value  , this.props.match.params.id)
  }

  render() {
    // console.log(this.props.campaigns && this.props.campaigns.statuses ? this.props.campaigns.statuses : [])
    const {notifications} = this.props;
    const style = {
      NotificationItem: { // Override the notification item
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          margin: '10px 5px 2px 1px'
        },
      }
    }
    return (
      <div className="wrapper">
        <Notifications
        notifications={notifications}
        style={style}
      />
        <Sidebar {...this.props} />
        <div
          className={
            `main-panel${
              this.props.location.pathname === '/maps/full-screen-maps'
                ? ' main-panel-maps'
                : ''}`
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
          <div className="content">
            <div className="clearfix">
              <div className="col-md-6">
                <Card
                  title="Campaign details"
                  statsIcon="fa fa-check"
                  content={(
                    <div className="contents">
                      <div className="row">
                        <div className="col-xs-6"> Name </div>
                        <div className="col-xs-6">
                          {' '}
                          <span className="font-bold">
                            {' '}
                            {this.props.campaigns && this.props.campaigns.details ? this.props.campaigns.details[0].campaign_name : 'Not found' }
                            {' '}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-6"> Start Date </div>
                        <div className="col-xs-6">
                          {' '}
                          <span className="font-bold">
                            {' '}
                            {this.props.campaigns && this.props.campaigns.details ? this.props.campaigns.details[0].start_date : 'Not found' }
                            {' '}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-6"> End Date </div>
                        <div className="col-xs-6">
                          {' '}
                          <span className="font-bold">
                            {' '}
                            {this.props.campaigns && this.props.campaigns.details ? this.props.campaigns.details[0].end_date : 'Not found' }
                            {' '}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-6"> Status </div>
                        <div className="col-xs-6">
                          {' '}
                          <span className="font-bold">
                            {' '}
                            {this.props.campaigns && this.props.campaigns.details ? this.props.campaigns.details[0].status_name : 'Not found' }
                            {' '}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-6"> Campaign type </div>
                        <div className="col-xs-6">
                          {' '}
                          <span className="font-bold">
                            {' '}
                            {this.props.campaigns && this.props.campaigns.details ? this.props.campaigns.details[0].campaign_type :'Not found' }
                            {' '}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-6"> Shop Name </div>
                        <div className="col-xs-6">
                          {' '}
                          <span className="font-bold">
                            {' '}
                            {this.props.campaigns && this.props.campaigns.details ? this.props.campaigns.details[0].business_name :'Not found' }
                            {' '}
                          </span>
                        </div>
                      </div>
                    </div>
)}

                />
              </div>
              <div className="col-md-6">
                <Card
                  title="Actions"
                  statsIcon="fa fa-check"
                  content={(
                    <div className="contents">
                      <div className="row">
                        <div className="col-xs-6"> Status  </div>
                        <div className="col-xs-6">
                          <Select
                            placeholder="Change status"
                            name="Status"
                            value={ this.props.campaigns && this.props.campaigns.campaign_status ? this.props.campaigns.campaign_status.status_code : 0}
                            options={this.props.campaigns && this.props.campaigns.statuses ? this.props.campaigns.statuses :this.state.statuses}
                            onChange={value => this.onChangeState(value)}
                          />
                        </div>

                      {/* <Col xs={12}>
                        <Button onClick={ () => this.onChangeState()}>Change</Button>
                      </Col> */}
                      </div>
                    </div>
)}
                />
              </div>
              <div className="col-sm-12">
                <Card
                  title="Campaign Visit Details"
                  statsIcon="fa fa-check"
                  content={(
                    <div>
                      {this.renderCampaigns()}
                    </div>
)}
                />
              </div>

            </div>
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  campaigns: state.campaign,
  notifications:state.notifications
});
const mapActionsToProps = {
  onCampaignFetch: fetchCampaign,
  onUpdateStatus : changeStatus
};
export default connect(mapStateToProps, mapActionsToProps)(Campaigns);
