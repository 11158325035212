
import Dashboard from '../containers/Dashboard';
import Campaigns from '../containers/Campaigns'
import Users from '../containers/Dashboard';

var dashboardRoutes = [
  {
    path: "/dashboard/home",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  {
    path: "/dashboard/campaign",
    name: "Campaigns",
    icon: "pe-7s-graph",
    component: Campaigns
  },
  {
    path: "/dashboard/users",
    name: "Users",
    icon: "pe-7s-graph",
    component: Users
  }
]

export default dashboardRoutes;
