import {USER_LOGGED_IN, USER_LOGIN_FAILED , ANALYITICS_FETCHED , USER_LOGIN_ATTEMPT , USER_LOGIN_COMPLETED} from '../constants';

export default  function AuthReducer(state = [] , {type , payload}){
    switch(type){
        case USER_LOGGED_IN:return payload;
        case USER_LOGIN_FAILED:return null;
        case USER_LOGIN_ATTEMPT:return {loading:true};
        case USER_LOGIN_COMPLETED:return {loading:false};
        case ANALYITICS_FETCHED:return payload;
        default:return null;
    }
}